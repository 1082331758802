import React, { useEffect, useRef, useState } from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

import { locales } from '~/consts/locale';
import { NotFoundPage } from '~/layouts/NotFoundPage';
import { AgentRoot } from '~/pages/AgentRoot';
import { RootErrorPage } from '~/pages/RootErrorPage';
import { pages } from '~/pages/routes';

type ReactRouter = ReturnType<typeof createBrowserRouter>;

function createLanguageRoutes(children: RouteObject[]): RouteObject[] {
  return [
    ...locales.map((lang) => ({
      path: lang,
      children,
    })),
    ...children,
  ];
}

export function Router() {
  const created = useRef(false);
  const [router, setRouter] = useState<ReactRouter>();

  useEffect(() => {
    if (!created.current) {
      // createBrowserRouter is only called when the Router is actually rendered, because createBrowserRouter prefetches lazy routes based on the current URL.
      // This would cause many unnecessary requests that might get canceled if the user is redirected to keycloak.
      setRouter(
        createBrowserRouter([
          {
            path: '',
            element: <AgentRoot />,
            errorElement: <RootErrorPage />,
            children: createLanguageRoutes(pages),
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ]),
      );

      created.current = true;
    }
  }, []);

  if (!router) {
    return null;
  }

  return <RouterProvider router={router} />;
}
